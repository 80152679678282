import React, { useState } from "react";
import { Stack, Switch, TextField, Typography } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import MuiPhoneNumber from "material-ui-phone-number";
import { isValidPhoneNumber } from "react-phone-number-input";
import Mailgun from "mailgun.js";
import formData from "form-data";
import { useMutation } from "@apollo/client";
import { REQUEST_INFO } from "../../queryDefinitions";
import { RequestMoreInfoMutationVariables, RequestMoreInfoMutation } from "../../__generated__/graphql";
import LivekvkSearchBox from "../landing_page/salesTimeslot/LivekvkSearchBox";
import { LoadingButton } from "@mui/lab";
import { Check } from "@mui/icons-material";

interface Props {
    a?: number;
}

export interface TYPE {
    name: string;
    email: string;
    phone: string;
    city: string;
    company: { handelsnaam: string; dossiernummer: string };
}

const mailgun = new Mailgun(formData);

// Client to check if email is valid
// Will be executed onblur()
const mailCheck = mailgun.client({
    username: "api",
    key: process.env.MAILGUN_API_KEY || "ef2e3b34540bd33c144d971204d9595a-3b1f59cf-9684710f",
    url: "https://api.eu.mailgun.net",
});

const Form: React.FC<Props> = (props) => {
    const [valid, setValid] = useState<boolean>(false);
    const [emailChecked, setEmailChecked] = useState<boolean>(false);
    const [phoneOrMail, setPhoneOrMail] = useState<boolean>(true);
    const [city, setCity] = useState<string>("");
    const [searchterm, setSearchterm] = useState<string>("");
    const [, setChosenOrg] = useState();
    const [completed, setCompleted] = useState<boolean>(false);
    const [, setNoResponse] = useState<string>("");
    // see BookingRoot.tsx
    const {
        handleSubmit: submit,
        formState: { errors, isValid },
        reset,
        resetField,
        control,
    } = useForm({
        mode: "all",
        defaultValues: {
            name: "",
            email: "",
            phone: "",
            city: "",
            company: { handelsnaam: "", dossiernummer: "" },
        },
    });

    /**
     * @param str input that has to be capitalized (first letter)
     * @returns string with capitalized first letter
     */
    function capitalizeFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    /**
     * Function to check if email exist
     * @param email user input in field: "email"
     */
    const validateMail = (email: string) => {
        (async () => {
            try {
                const validationRes = await mailCheck.validate.get(email);
                if (validationRes) {
                    if (validationRes.result === "deliverable") {
                        setValid(true);
                        setEmailChecked(true);
                    } else {
                        setValid(false);
                        setEmailChecked(true);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        })();
    };

    // const nameOFPerson = removeSpacesBeforeAfter(data.givenname);
    // const famName = removeSpacesBeforeAfter(data.familyname);
    // const work = removeSpacesBeforeAfter(data.function);
    // const mobile = isPhoneNumberAllowed(data.phone);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneOrMail(event.target.checked);
        resetField("phone");
        resetField("email");
    };

    /**
     * Book timeslot
     */
    const [book, { error: errorMutation, loading: loadingMutation }] = useMutation<RequestMoreInfoMutation, RequestMoreInfoMutationVariables>(
        REQUEST_INFO
    );

    // Created to test error messages & input values
    const onSubmit: SubmitHandler<TYPE> = async (data) => {
        // setFormData(data);

        // const nameOFPerson = removeSpacesBeforeAfter(data.givenname);
        // const famName = removeSpacesBeforeAfter(data.familyname);
        // const work = removeSpacesBeforeAfter(data.function);
        // const mobile = isPhoneNumberAllowed(data.phone);

        if (errorMutation) {
        }
        try {
            await book({
                variables: {
                    company: data.company.handelsnaam ?? searchterm,
                    kvk: data.company.dossiernummer ?? "",
                    name: data.name,
                    email: data.email,
                    city: data.city,
                    phone: data.phone,
                },
                onCompleted: () => {
                    setCompleted(true);
                    reset({
                        name: "",
                        email: "",
                        phone: "",
                        city: "",
                        company: { handelsnaam: "", dossiernummer: "" },
                    });
                },
            });
        } catch (e) {}
    };

    return (
        <div>
            <Typography className="h1" style={{ paddingTop: "48px", paddingBottom: "0px" }} variant="h1">
                Meer informatie?
            </Typography>
            <Stack direction="row" sx={{ alignItems: "center", display: "flex", width: "100%", paddingBottom: "24px" }}>
                <Typography
                    sx={{
                        width: "100%",
                        textAlign: "end",
                        fontWeight: !phoneOrMail ? 500 : 400,
                        color: !phoneOrMail ? "#173357" : "#00000091",
                    }}
                >
                    Mail mij
                </Typography>
                <Switch onChange={handleChange} checked={phoneOrMail} size="small" defaultChecked />
                <Typography
                    sx={{
                        width: "100%",
                        fontWeight: phoneOrMail ? 500 : 400,
                        color: phoneOrMail ? "#173357" : "#00000091",
                    }}
                >
                    Bel mij
                </Typography>
            </Stack>
            <form onSubmit={submit(onSubmit)} style={{ width: "100%" }}>
                <Controller
                    control={control}
                    rules={{
                        required: "Naam is verplicht",
                        minLength: { value: 3, message: "Minimaal 3 karakters invoeren" },
                        maxLength: { value: 20, message: "max. 20 karakters" },
                    }}
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            variant="filled"
                            autoComplete="off"
                            error={Boolean(errors.name)}
                            helperText={Boolean(errors.name) ? errors.name?.message : " "}
                            fullWidth
                            value={value}
                            onChange={(event) => {
                                onChange(capitalizeFirstLetter(event.target.value));
                            }}
                            label="Naam"
                            sx={{ marginBottom: 0, marginRight: 0 }}
                        />
                    )}
                    name="name"
                />

                <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => {
                        const onChangeField = (e: any) => {
                            setCity(e.target.value);
                        };
                        return (
                            <TextField
                                variant="filled"
                                autoComplete="off"
                                error={Boolean(errors.city)}
                                helperText={Boolean(errors.city) ? errors.city?.message : " "}
                                fullWidth
                                value={value}
                                onChange={(event) => {
                                    onChangeField(event);
                                    onChange(capitalizeFirstLetter(event.target.value));
                                }}
                                label="Plaatsnaam"
                                sx={{ marginBottom: 0, marginRight: 0 }}
                            />
                        );
                    }}
                    name="city"
                />

                {/*
                 * Company name
                 * add service to check
                 */}
                <LivekvkSearchBox
                    noKvkResponse={(response) => setNoResponse(response)}
                    variant="filled"
                    setSearchterm={setSearchterm}
                    searchterm={searchterm}
                    errors={errors}
                    control={control}
                    city={city}
                    setChosenOrg={setChosenOrg}
                />

                {/*
                 * Phone number with own validation and custom component (installed package)
                 */}
                {phoneOrMail ? (
                    <Controller
                        name="phone"
                        control={control}
                        rules={{
                            required: "Telefoonnummer is verplicht",
                            validate: (value) => isValidPhoneNumber(value),
                            minLength: { value: 10, message: "Geen geldig telefoonnummer" },
                        }}
                        render={({ field: { onChange } }) => (
                            <MuiPhoneNumber
                                autoComplete="off"
                                variant="filled"
                                sx={{
                                    marginBottom: 0,
                                    width: "100%",
                                    marginLeft: 0,
                                    marginTop: 0,
                                    "& .MuiInputAdornment-root": {
                                        marginTop: "0px !important", // Removes the margin-top
                                    },
                                    "& .MuiInputBase-input": {
                                        paddingTop: "16px !important", // Example: Adjust padding
                                        paddingBottom: "16px !important", // Example: Adjust padding
                                    },
                                    "& .MuiIconButton-root": {
                                        padding: "0px", // Adjust padding
                                    },
                                }}
                                error={Boolean(errors.phone)}
                                helperText={Boolean(errors.phone) ? errors.phone?.message : " "}
                                defaultCountry={"nl"}
                                onChange={onChange}
                            />
                        )}
                    />
                ) : (
                    // {/*
                    //  * Email controller
                    //  * custom pattern to check if its an email format
                    //  * */}
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: "E-mailadres is verplicht",
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "Invoer is geen geldig e-mailadres",
                            },
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                fullWidth
                                variant="filled"
                                autoComplete="off"
                                error={Boolean(errors.email) || (value.length > 6 && valid === false)}
                                helperText={
                                    Boolean(errors.email)
                                        ? errors.email?.message
                                        : valid === false && value.length > 3 && emailChecked
                                        ? "Je hebt geen geldig e-mailadres ingevuld"
                                        : " "
                                }
                                value={value}
                                onChange={(event) => {
                                    onChange(event.target.value.toLocaleLowerCase());
                                }}
                                onBlur={(event) => validateMail(event.target.value.toLocaleLowerCase())}
                                label="E-mail"
                                sx={{
                                    marginBottom: 0,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    flex: 1,
                                }}
                            />
                        )}
                    />
                )}

                {completed ? (
                    <Typography
                        sx={{ width: "100%", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}
                        variant="button"
                    >
                        <Check sx={{ color: "success.main", marginRight: "4px" }} /> gelukt!
                    </Typography>
                ) : (
                    <LoadingButton
                        disabled={!isValid}
                        type="submit"
                        loading={loadingMutation}
                        fullWidth
                        sx={{ marginTop: "16px" }}
                        variant="contained"
                    >
                        Versturen
                    </LoadingButton>
                )}
            </form>
        </div>
    );
};

export default Form;
