import React, { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { TextField, Autocomplete, CircularProgress, useTheme, useMediaQuery } from "@mui/material";
import { searchKvk } from "../../../utils/kvkSearch";
import { debounce } from "lodash";

interface Props {
    errors: any;
    control: any;
    city: string;
    setChosenOrg(chosenOrg: any): void;
    searchterm: string;
    setSearchterm(searchTerm: string): void;
    variant?: string;
    noKvkResponse(r: string): void;
}

const LivekvkSearchBox: React.FC<Props> = ({ errors, control, city, setChosenOrg, searchterm, setSearchterm, variant, noKvkResponse }) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const [results, setResults] = useState<any[]>([]);
    // const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [bedrijfsnaam, setBedrijfsnaam] = useState<string>("");

    const searchFunctionDebounced = useRef(
        debounce(async (naam, plaats) => {
            // Don't execute the search if all inputs are empty
            if (!naam && !plaats) {
                setResults([]); // Optionally clear the results if inputs are empty
                return;
            }
            try {
                setLoading(true);
                const response = await searchKvk(naam, plaats);
                // Handle case where response is a single object (KvkApiResponse)
                // Handle the error case where no results are found
                if (response.fout && response.fout.length > 0) {
                    const errorCode = response.fout[0].code;
                    const errorMessage = response.fout[0].omschrijving;

                    if (errorCode === "IPD5200") {
                        // Handle the "no data found" case
                        setResults([]); // Clear results if no data is found
                        noKvkResponse(errorMessage);
                    }
                    noKvkResponse("Er ging iets mis");
                } else {
                    // Handle case where response contains results
                    setResults(response.resultaten);
                }
            } catch (e) {
                console.error("Error loading search results", e);
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 400);
            }
        }, 400)
    ).current;

    // Handle change for Search Text
    const handleNameChange = (n: string) => {
        setBedrijfsnaam(n);
        searchFunctionDebounced(n, city); // Pass arguments for the debounced function
    };

    // refetch when user updates city value
    useEffect(() => {
        searchFunctionDebounced(bedrijfsnaam, city); // Pass arguments for the debounced function
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [city]);

    const optionsToreturn = () => {
        // filter results on type "Hoofdvestiging" & actief = "1"
        const filteredResponseHV = results
            .filter((res) => res.type === "hoofdvestiging")
            .filter((res) => res.actief === undefined || res.actief === "Ja");

        // filter results on type "Rechtspersoon" & actief = "1"
        const filteredResponseRP = results
            .filter((res) => res.subType === "rechtspersoon")
            .filter((res) => res.actief === undefined || res.actief === "Ja");

        // first show "hoofdvestiging" if not -> show "rechtspersoon" otherwise show response where actief = "1"
        const dataToShow =
            filteredResponseHV.length > 0
                ? filteredResponseHV
                : filteredResponseRP.length > 0
                ? filteredResponseRP
                : results.filter((res) => res.actief === undefined || res.actief === "Ja");

        return dataToShow;
    };

    return (
        <Controller
            control={control}
            name="company"
            rules={{
                required: "Organisatie is verplicht",
            }}
            render={({ field: { onChange, value, onBlur } }) => (
                <Autocomplete
                    freeSolo
                    // open={open}
                    onBlur={() => {
                        onBlur();
                    }}
                    // open dropdown if city is over 0 characters, search is over 2 and results are available
                    // onOpen={() => {
                    //     if (optionsToreturn().length > 0 && searchterm.length > 2) {
                    //         setOpen(true);
                    //     }
                    // }}
                    // close dropdown
                    // onClose={() => {
                    //     setOpen(false);
                    // }}
                    disableClearable
                    sx={{ marginBottom: noMobile ? "8px" : 0, flex: 1, marginLeft: noMobile ? "4px" : 0 }}
                    // list of found companies
                    options={optionsToreturn()}
                    // typed input
                    onInputChange={(event, newInputValue) => {
                        // Remove chosenOrg details if user changes input
                        // added to avoid mismatch in kvk data and given company name
                        // setChosenOrg(undefined);
                        // set 'handelsnaam' to user input and set 'dossiernummer' to null
                        onChange({ handelsnaam: newInputValue, dossiernummer: "null" });
                    }}
                    // selected input
                    onChange={(_event, values, reason) => {
                        if (reason === "clear") {
                            setSearchterm("");
                        } else {
                            // Set chosenOrg to clicked dropdownitem
                            onChange({ handelsnaam: values.naam, dossiernummer: values.kvknummer });
                        }

                        setChosenOrg(values);
                        setSearchterm("");
                    }}
                    loading={loading}
                    loadingText="Zoeken..."
                    value={value}
                    filterOptions={(x) => x}
                    getOptionLabel={(option) => {
                        return option ? `${option.handelsnaam}` : "";
                    }}
                    renderOption={(props, option) => <li {...props}>{option.naam}</li>}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant={variant ? (variant as "outlined" | "standard" | "filled" | undefined) : "outlined"}
                            onChange={(e) => {
                                handleNameChange(e.target.value);
                                setSearchterm(e.target.value);
                                // if (e.target.value.length === 0) {
                                //     setOpen(false);
                                // }
                            }}
                            error={Boolean(errors.company)}
                            helperText={Boolean(errors.company) ? errors.company.message : " "}
                            label="Organisatie"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            )}
        />
    );
};

export default LivekvkSearchBox;
