import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css"; // Import Swiper styles
import { Pagination, Navigation, Autoplay, EffectCards } from "swiper"; // Import required modules
import { Card, Typography } from "@mui/material";
import "./cardContentSlide.scss";
import HumanAndMachine from "../../assets/humandAndMachine.svg";
import Contract from "../../assets/contract.svg";
import Search from "../../assets/searchhighlight.svg";
import Export from "../../assets/export_2.svg";
import Notification from "../../assets/notification.svg";
import Budget from "../../assets/budget.svg";

// Sample items for the slider
const items = [
    {
        id: 1,
        title: "Het beste van <bold>mens</bold> en <bold>machine</bold> ineen",
        description: `<bold>Handmatige verrijking</bold>gecombineerd met intuïtieve interfaces en de <bold>krachtigste technieken.</bold> De belangrijkste gegevens in <bold>één oogopslag</bold> voor een snellere go/no-go.`,
        img: HumanAndMachine,
    },
    {
        id: 2,
        title: "<bold>Vind wat ertoe doet</bold>",
        description: `Ervaar een <bold>ongekende zoekkracht.</bold> Stop met overal zoeken en <bold>vind alles op één plek.</bold> Op de manier waarop jij dat wilt. Eenvoudig of complex.`,
        img: Contract,
    },
    {
        id: 3,
        title: "<bold>Volg en anticipeer</bold>",
        description: `Krijg <bold>updates</bold> over aanbestedingen, marktpartijen en aanbestedende diensten die jou interesseren. Bekijk <bold>verrijkte organisatieprofielen</bold> en volg specifieke spelers of anticipeer op gebeurtenissen in jouw sector.`,
        img: Budget,
    },
    {
        id: 4,
        title: "<bold>Plan meerdere jaren vooruit</bold>",
        description: `Kijk terug in de tijd binnen jouw <bold>persoonlijke bibliotheek.</bold> Naar het voorgaande contract. Of dat daarvoor. Wees tijdig voorbereid en hou meer tijd over voor beïnvloeding.`,
        img: Search,
    },
    {
        id: 5,
        title: "<bold>Signaleer kansen</bold>",
        description: `Timing is essentieel. <bold>Herken</bold> vroegtijdig toekomstige <bold>investeringen</bold> en maak jouw <bold>eigen overzichten.</bold> Breng meer focus aan in het beïnvloeden van overheden.`,
        img: Export,
    },
    {
        id: 6,
        title: "<bold>Overzichten en exports</bold>",
        description: `Stel <bold>eigen overzichten</bold> samen of <bold>exporteer resultaten</bold> met aanbestedingen uit ons archief van <bold>meer dan 10 jaar.</bold>`,
        img: Notification,
    },
];

const CardContentSlide: React.FC = () => {
    // const [activeIndex, setActiveIndex] = useState(0);

    return (
        <Swiper
            className="customSwipe"
            effect="cards"
            modules={[Pagination, Navigation, Autoplay, EffectCards]} // Add Autoplay, Pagination, Navigation
            slidesPerView={"auto"} // Display one slide at a time
            loopedSlides={0}
            loop={true} // Infinite loop
            // autoplay={{
            //     delay: 3000, // Auto-play delay (3 seconds)
            //     disableOnInteraction: false, // Keeps autoplay running even when manually interacting with the slider
            // }}
            cardsEffect={{ rotate: false, slideShadows: false, perSlideRotate: 2, perSlideOffset: 15 }}
            // onSlideChange={(swiper) => {
            //     setActiveIndex(swiper.activeIndex);
            // }}
        >
            {items.map((item, index) => {
                return (
                    <SwiperSlide
                        key={index}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div style={{ width: "300px", height: "100%" }}>
                            <Card
                                elevation={0}
                                className="cardinslide"
                                sx={{
                                    height: "100%",
                                    padding: "0px 32px",
                                    borderRadius: "24px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>
                                    <Typography
                                        textAlign={"center"}
                                        whiteSpace={"pre-wrap"}
                                        sx={{ color: "#000", mb: "16px", mt: "48px" }}
                                        variant="h1"
                                        fontWeight={600}
                                        component="div"
                                        dangerouslySetInnerHTML={{
                                            __html: item.title,
                                        }}
                                    />
                                    <Typography
                                        textAlign={"center"}
                                        sx={{ fontSize: "16px !important", color: "#000", mb: "12px" }}
                                        dangerouslySetInnerHTML={{
                                            __html: item.description,
                                        }}
                                    />
                                </div>
                                <img src={item.img} alt={item.title} style={{ margin: "16px 0px", flex: 1 }} />
                            </Card>
                        </div>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
};

export default CardContentSlide;
