import React, { useState } from "react";
import { Checkbox, Grid, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Controller } from "react-hook-form";
import { Disable } from "react-disable";
import MuiPhoneNumber from "material-ui-phone-number";
import { isValidPhoneNumber } from "react-phone-number-input";
import LivekvkSearchBox from "./LivekvkSearchBox";

import formData from "form-data";
import Mailgun from "mailgun.js";
const mailgun = new Mailgun(formData);

// Client to check if email is valid
// Will be executed onblur()
const mailCheck = mailgun.client({
    username: "api",
    key: process.env.MAILGUN_API_KEY || "ef2e3b34540bd33c144d971204d9595a-3b1f59cf-9684710f",
    url: "https://api.eu.mailgun.net",
});

interface Props {
    loading: boolean;
    errors: any;
    control: any;
    setChosenOrg(chosenOrg: any): void;
    chosenTimeSlot: string;
    searchterm: string;
    setSearchterm(searchterm: string): void;
}

const UserInfoForm: React.FC<Props> = ({ loading, errors, control, setChosenOrg, searchterm, setSearchterm, chosenTimeSlot }) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const [city, setCity] = useState("");
    const [valid, setValid] = useState<boolean>(false);
    const [, setNoresponse] = useState<string>("");
    /**
     * @param str input that has to be capitalized (first letter)
     * @returns string with capitalized first letter
     */
    function capitalizeFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    /**
     * Function to check if email exist
     * @param email user input in field: "email"
     */
    const validateMail = (email: string) => {
        (async () => {
            try {
                const validationRes = await mailCheck.validate.get(email);
                if (validationRes) {
                    if (validationRes.result === "deliverable") {
                        setValid(true);
                    } else setValid(false);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    };

    return (
        <div>
            <Typography variant="h1" style={{ marginBottom: "30px" }}>
                Contactgegevens voor de demo
            </Typography>
            <Disable
                disabled={false}
                //  disabled={loading || chosenTimeSlot === ""}
            >
                <Grid container spacing={4} justifyContent="space-between">
                    {/* Grid item for all the formfields on the left side. */}
                    <Grid item xs={12} sm={12} md>
                        {/* Textfield givenname and familyname */}
                        <section style={{ display: "flex", flexDirection: noMobile ? "row" : "column" }}>
                            {/* Name of person
                             * min 3 characters
                             * max 20 characters
                             * pattern to allow letters only
                             */}
                            <Controller
                                control={control}
                                rules={{
                                    required: "Voornaam is verplicht",
                                    minLength: { value: 3, message: "Minimaal 3 karakters invoeren" },
                                    maxLength: { value: 20, message: "max. 20 karakters" },
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        autoComplete="off"
                                        error={Boolean(errors.givenname)}
                                        helperText={Boolean(errors.givenname) ? errors.givenname.message : " "}
                                        fullWidth
                                        value={value}
                                        onChange={(event) => {
                                            onChange(capitalizeFirstLetter(event.target.value));
                                        }}
                                        label="Voornaam"
                                        sx={{ marginBottom: noMobile ? "8px" : 0, marginRight: noMobile ? "4px" : 0 }}
                                    />
                                )}
                                name="givenname"
                            />
                            {/* Family name of person
                             * min 3 characters
                             * max 30 characters
                             * pattern to allow letters only
                             */}
                            <Controller
                                control={control}
                                rules={{
                                    required: "Achternaam is verplicht",
                                    minLength: { value: 3, message: "Minimaal 3 karakters invoeren" },
                                    maxLength: { value: 30, message: "max. 20 karakters" },
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        autoComplete="off"
                                        error={Boolean(errors.familyname)}
                                        helperText={Boolean(errors.familyname) ? errors.familyname.message : " "}
                                        fullWidth
                                        value={value}
                                        onChange={(event) => {
                                            onChange(capitalizeFirstLetter(event.target.value));
                                        }}
                                        label="Achternaam"
                                        sx={{ marginBottom: noMobile ? "8px" : 0, marginLeft: noMobile ? "4px" : 0 }}
                                    />
                                )}
                                name="familyname"
                            />
                        </section>
                        {/* Function, email and phone number */}
                        <section style={{ display: "flex", flexDirection: noMobile ? "row" : "column" }}>
                            {/* Function of person
                             * min 3 characters
                             * max 30 characters
                             * pattern to allow letters only
                             */}
                            <Controller
                                control={control}
                                // rules={{
                                //     required: "Dit veld is verplicht",
                                //     minLength: { value: 3, message: "Minimaal 3 karakters invoeren" },
                                //     maxLength: { value: 30, message: "max. 20 karakters" },
                                //     pattern: { value: /^[A-Za-z]+$/i, message: "Alleen letters toegestaan" },
                                // }}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        autoComplete="off"
                                        error={Boolean(errors.function)}
                                        helperText={Boolean(errors.function) ? errors.function.message : " "}
                                        fullWidth
                                        value={value}
                                        onChange={(event) => {
                                            onChange(capitalizeFirstLetter(event.target.value));
                                        }}
                                        label="Functie"
                                        sx={{
                                            marginBottom: noMobile ? "8px" : 0,
                                            marginRight: noMobile ? "4px" : 0,
                                            width: noMobile ? "20ch" : "100%",
                                        }}
                                    />
                                )}
                                name="function"
                            />
                            {/*
                             * Email controller
                             * custom pattern to check if its an email format
                             * */}
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: "E-mailadres is verplicht",
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: "Invoer is geen geldig e-mailadres",
                                    },
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        autoComplete="off"
                                        error={Boolean(errors.email) || (value.length > 6 && valid === false)}
                                        helperText={
                                            Boolean(errors.email)
                                                ? errors.email.message
                                                : valid === false && value.length > 3
                                                ? "Je hebt geen geldig e-mailadres ingevuld"
                                                : " "
                                        }
                                        value={value}
                                        onChange={(event) => {
                                            onChange(event.target.value.toLocaleLowerCase());
                                        }}
                                        onBlur={(event) => validateMail(event.target.value.toLocaleLowerCase())}
                                        label="E-mail"
                                        sx={{
                                            marginBottom: noMobile ? "8px" : 0,
                                            marginLeft: noMobile ? "4px" : 0,
                                            marginRight: noMobile ? "4px" : 0,
                                            flex: 1,
                                        }}
                                    />
                                )}
                            />
                            {/*
                             * Phone number with own validation and custom component (installed package)
                             */}
                            <Controller
                                name="phone"
                                control={control}
                                rules={{
                                    required: "Telefoonnummer is verplicht",
                                    validate: (value) => isValidPhoneNumber(value),
                                    minLength: { value: 10, message: "Geen geldig telefoonnummer" },
                                }}
                                render={({ field: { onChange } }) => (
                                    <MuiPhoneNumber
                                        autoComplete="off"
                                        variant="outlined"
                                        sx={{
                                            marginBottom: noMobile ? "8px" : 0,
                                            width: noMobile ? "26ch" : "100%",
                                            marginLeft: noMobile ? "4px" : 0,
                                        }}
                                        error={Boolean(errors.phone)}
                                        helperText={Boolean(errors.phone) ? errors.phone.message : " "}
                                        defaultCountry={"nl"}
                                        onChange={onChange}
                                    />
                                )}
                            />
                        </section>
                        {/*
                         * Company city
                         */}
                        <section style={{ display: "flex", flexDirection: noMobile ? "row" : "column" }}>
                            <Controller
                                control={control}
                                rules={{
                                    required: "Plaats is verplicht",
                                }}
                                render={({ field: { onChange, onBlur, value } }) => {
                                    const onChangeField = (e: any) => {
                                        onChange(e);
                                        setCity(e);
                                    };
                                    return (
                                        <TextField
                                            autoComplete="off"
                                            error={Boolean(errors.city)}
                                            helperText={Boolean(errors.city) ? errors.city.message : " "}
                                            onBlur={onBlur}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                onChangeField(capitalizeFirstLetter(event.target.value));
                                            }}
                                            value={value}
                                            label="Plaats"
                                            sx={{
                                                marginBottom: noMobile ? "8px" : 0,
                                                marginRight: noMobile ? "4px" : 0,
                                                width: noMobile ? "30ch" : "100%",
                                            }}
                                        />
                                    );
                                }}
                                name="city"
                            />

                            {/*
                             * Company name
                             * add service to check
                             */}
                            <LivekvkSearchBox
                                noKvkResponse={(r) => setNoresponse(r)}
                                setSearchterm={setSearchterm}
                                searchterm={searchterm}
                                errors={errors}
                                control={control}
                                city={city}
                                setChosenOrg={setChosenOrg}
                            />
                        </section>
                        <section style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                            <label>Ik geef toestemming om mijn gegevens te verwerken</label>
                            <Controller
                                name="Checkbox"
                                control={control}
                                rules={{
                                    required: "Veld is verplicht",
                                }}
                                render={({ field }) => <Checkbox required {...field} />}
                            />
                        </section>
                    </Grid>
                </Grid>
            </Disable>
        </div>
    );
};

export default UserInfoForm;
