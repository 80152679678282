import React, { useEffect } from "react";
import SectionDivider from "../../components/SectionDivider";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import TestimonialSection from "../landing_page/TestimonialSection";
import { ReactComponent as LogoTG } from "../../assets/tenderguide_logo.svg";
import "./layout.scss";
// import ReactTypingEffect from "react-typing-effect";
import CardContentSlide from "./CardContentSlide";
import Form from "./Form";

interface Props {
    a?: number;
}

const Layout: React.FC<Props> = (props) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const tablet = useMediaQuery(theme.breakpoints.up("lg"));

    // const [showElements, setShowElements] = useState(false);
    const handleScroll = () => {
        const elements = document.querySelectorAll(".fade-in");
        const scrollPosition = window.scrollY + window.innerHeight;

        elements.forEach((el: any, index) => {
            const elementPosition = el.getBoundingClientRect().top + window.scrollY;
            if (scrollPosition > elementPosition) {
                // setShowElements(true);
                el.classList.add("show");
                el.style.setProperty("--delay", `${index * 0.2}s`); // Setting delay based on index
            }
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        // Trigger scroll event on page load to check initial visibility
        handleScroll();

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <div className="demolayout">
            <div className="fade-in" style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                <LogoTG width="100%" height="64px" style={{ height: "80px", marginTop: "-16px" }} className="logo" />
            </div>
            <div
                className="fade-in"
                style={{
                    padding: "72px 0px 0px",
                }}
            >
                <Typography className="heroheader" variant="h1">
                    Voor iedereen die zaken doet met overheden
                </Typography>
                <Typography className="herosubheader" style={{ marginTop: "32px" }}>
                    Ontdek TenderGuide en boost de kracht van jouw sales- en tenderteams
                </Typography>
            </div>
            {/*
             * Whitespace
             * Custom spacer for devices
             */}
            <SectionDivider margin={tablet ? "0px 0px" : noMobile ? "128px 0px" : "48px"} />
            <div className="fade-in">
                <Form />
            </div>
            {/*
             * Whitespace
             * Custom spacer for devices
             */}
            <SectionDivider margin={tablet ? "96px 0px" : noMobile ? "48px 0px" : "0"} />
            {/*
             * Testimonial carousel
             * show company logo's in black/white
             * Stop rotate and show colored logo on hover
             */}
            <div className="fade-in" style={{ margin: "32px -32px 0px" }}>
                <TestimonialSection colored />
            </div>

            {/*
             * Whitespace
             * Custom spacer for devices
             */}
            <SectionDivider margin={tablet ? "160px 0px" : noMobile ? "64px 0px" : "96px 0px"} />
            <div className="fade-in">
                <CardContentSlide />
            </div>
            <SectionDivider margin={tablet ? "0px 0px" : noMobile ? "128px 0px" : "64px"} />
        </div>
    );
};

export default Layout;
