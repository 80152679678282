import axios from "axios";
import { KvkApiParameters, KvkApiResponse } from "./kvkTypesV2";
export type SearchFunction<T = any> = (query: string, ...args: any[]) => Promise<T[]>;

const kvkApi = axios.create({
    baseURL: "https://api.tender.guide/api/v1/kvk-proxy",
    headers: {
        accept: "application/json",
    },
});

export const searchKvk = async (naam?: string, plaats?: string): Promise<KvkApiResponse> => {
    // Prepare params object, only include valid fields
    const params: KvkApiParameters = {};

    if (naam) {
        params.naam = naam;
    }

    if (plaats) {
        params.plaats = plaats;
    }

    try {
        const response = await kvkApi.get("", {
            params: {
                ...params,
                resultatenPerPagina: 50,
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching KVK data:", error);
        throw error; // Re-throw error to be caught later in component
    }
};
